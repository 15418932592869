import React, { Fragment, useContext, useEffect, useState } from "react"

interface ContextProps {
  shop: string
  sessionToken?: string
  appName?: string
}

export const AppConfigContext = React.createContext({} as ContextProps)
export const getAppConfig = () => useContext(AppConfigContext)
export const AppConfigProvider: React.FC<ContextProps> = ({
  shop: initialShop,
  sessionToken: initialToken,
  appName: initialName,
  children,
}) => {
  //   const [shop, setShop] = useState(initialShop)
  //   const [sessionToken, setSessionToken] = useState(initialToken)
  //   const [appName, setAppName] = useState(initialName)
  return (
    // <AppConfigContext.Provider value={{ shop,sessionToken,appName }}>
    <AppConfigContext.Provider
      value={{
        shop: initialShop,
        sessionToken: initialToken,
        appName: initialName,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}
