import firebase from 'firebase/app';
import 'firebase/auth'; // importing the auth module as an example
import 'firebase/firestore'
import 'firebase/functions'
import { useState, useEffect } from 'react';

// const config = {
//     apiKey: process.env.FIREBASE_WEB_API_KEY,
//     authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.FIREBASE_DATABASE_URL,
//     projectId: process.env.FIREBASE_PROJECT_ID,
// };

const config = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
};


let instance: firebase.app.App | null = null;

export const getFirebase = () => {
    if (typeof window !== 'undefined') {
        if (instance) return instance;
        instance = firebase.initializeApp(config);

        if( process.env.GATSBY_FIRESTORE_EMULATOR === 'true') {
                    // Should probably make these environment variables also
                    const dbSettings = {
                            host: "localhost:8080",
                            ssl: false
                    }
                    console.log('Emulating firestore locally', {dbSettings})
                    // firebase.firestore().settings(dbSettings);
                    // app.firestore().settings(dbSettings)
                    instance.firestore().settings(dbSettings)
                    // if (typeof firebase.functions === 'function' ) {
                    // if (typeof app.functions === 'function' ) {
                        // firebase.functions().useFunctionsEmulator("http://localhost:5001");
                        // firebase.functions().useEmulator("localhost",5001);
                        // app.functions().useEmulator("localhost",5001);
                        instance.functions().useEmulator("localhost",5001);
                    // } else {
                    //     console.log('Cloud not set local emulator for functions');
                    // }
                }

        return instance;
      }
    
      return null;
    // if (typeof firebase.apps !== 'undefined' && firebase.apps.length){
    //     return firebase.apps[0];
    // }


    // firebase.initializeApp(config)
    // if (UID) {
    //     firebase.auth().signInWithCustomToken(UID).catch((error: any) => {
    //         console.warn('Unable to signin with custom token', error.message);
    //         console.info(`Tried ${UID}`)
    //     });
    // }

    // if( process.env.GATSBY_FIRESTORE_EMULATOR === 'true') {
    //     // Should probably make these environment variables also
    //     const dbSettings = {
    //             host: "localhost:8080",
    //             ssl: false
    //     }
    //     console.log('Emulating firestore locally', {dbSettings})
    //     firebase.firestore().settings(dbSettings);
    //     if (typeof firebase.functions === 'function' ) {
    //         firebase.functions().useFunctionsEmulator("http://localhost:5001");
    //     } else {
    //         console.log('Cloud not set local emulator for functions');
    //     }
    // }

    // return firebase
}

export const useFirebase = () => {
    const [instance, setInstance] = useState<firebase.app.App|null>(null);

    useEffect(() => {
      setInstance(getFirebase());
    }, []);
  
    return instance;    
}