import React, { useContext, useEffect, useState } from "react"
import { DisplayText, Layout, SkeletonPage, Spinner, Stack } from "@shopify/polaris"
import { Context, Loading } from "@shopify/app-bridge-react"
import { appName } from "../../helpers/app"
import { ShopifyAppBrideState } from "../../helpers/app-props"

export const AppSplash: React.FC<{
  displayName?: string
}> = ({displayName}) => {
  return (
    <SkeletonPage primaryAction={false} fullWidth>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "calc(100vh - 2rem)",
          }}
        >
        <Stack alignment="center" vertical>
          <DisplayText size="large">Loading {displayName}</DisplayText>
        </Stack>
        </div>
        {/* <Loading /> */}
      </Layout>
    </SkeletonPage>
  )
}
