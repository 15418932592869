import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useFirebase } from "../helpers/firebase/loader"
import firebaseLib from "firebase/app"
// import { Context } from "@shopify/app-bridge"
import { Context } from "@shopify/app-bridge-react"

interface ContextProps {
  sessionToken?: string
  user?: firebaseLib.User | null
  isAuthenticated?: boolean
}

export const AuthContext = React.createContext({} as ContextProps)
export const getAuthContext = () => useContext(AuthContext)

// export const useFirebase = () => useContext(FirebaseContext);

export const AuthProvider: React.FC<ContextProps> = ({
  sessionToken,
  children,
  user: initialUser,
}) => {
  const waitBetweenChecksMs = 1_000 * 30
  const [user, setUser] = useState(initialUser)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [lastTokenCheck, setLastTokenCheck] = useState(0)
  const authApp = useFirebase()
  // const app = null as any;
  const getAppToken = useCallback(() => {
    try {
      return authApp
        ? authApp.functions().httpsCallable("shopifyAuthSession")
        : Promise.reject
    } catch (error) {
      console.error("Error getting app token %o", error)
    }
  }, [authApp])

  const checkForTokenUpdate = useCallback(() => {
    console.log("Checking for update %o %o", user, authApp)
    // If user is not set see if we can get a session token and exchange it with firebase
    if (!user && authApp) {
      const now = new Date().getTime()
      if (now - waitBetweenChecksMs > lastTokenCheck) {
        setLastTokenCheck(now)
        console.log("Checking for new token %o %o", user, authApp)
        const callable = getAppToken()
        callable && callable({
          credential: sessionToken,
        }).then((result) => {
          // console.log({ result })
          const { data, success } = result.data
          // console.log({ data })

          if (success && data) {
            const { customToken } = data
            if (typeof customToken === "string") {
              console.log({ customToken })

              authApp
                .auth()
                .signInWithCustomToken(customToken)
                .then((credential) => {
                  {
                    // console.log({ credential })
                  }
                })
                .catch((error) => {
                  console.log("sign in error %o", error)
                })
            }
          }
        })
      }
    } else {
      // console.log({ user, app: authApp })
    }
  }, [getAppToken, lastTokenCheck, sessionToken])

  useEffect(() => {
    // console.log({ authApp })
    if (authApp) {
      try {
        authApp.auth().onAuthStateChanged((a) => {
          // console.log({a})
          if (a) {
            // Check token is for this shop or logout
            a.getIdTokenResult().then((idTokenResult) => {
              const {claims} = idTokenResult 
              // console.log({claims})
                // setUser(a) 
              if (claims.shop) {
                // console.log('Setting user with shop', claims.shop)
                setUser(a) 
              } {
                // console.log('Not setting user to null', claims.shop)
                // setUser(null) 
              }
            })
          } else {
            // setUser(a) 
          }
        })
      } catch (error) {
        console.log("auth state change error %o", { error })
      }
    }
  }, [authApp])

  useEffect(() => {
    if (null === user && authApp) {
      // console.log("auth starting update", { user })
      checkForTokenUpdate()
    } else if (user) {
      // console.log("auth found user ", { user })
    }
  }, [user, authApp])

  useEffect(() => setIsAuthenticated(!!user), [user])
  // useEffect(() => console.log({ user }), [user])

  return (
    <AuthContext.Provider value={{ user, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}
