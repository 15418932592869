import React, { ReactChildren } from "react"
import { navigate } from "gatsby"

// import { ApolloClient } from "apollo-client"
// import { ApolloProvider } from "react-apollo"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client"
// import { ApolloLink } from "apollo-link"
// import { createHttpLink } from "apollo-link-http"
// import { setContext } from "apollo-link-context"
// import { onError } from "apollo-link-error"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"

import { clearAppCookies, refreshAuth } from "../helpers/auth"
import _ from "lodash"
import { has } from "../helpers/utils"
// type BaseConfig = {
//     children?: ReactChildren,
//     shop: string,
//     token?: string;
//     sessionToken?: string;
// };
// type TokenConfig = BaseConfig & {
//     token: string;
//     sessionToken: never;
// }
// type SessionTokenConfig = BaseConfig & {
//     token: never;
//     sessionToken: string;
// }
// type Params = BaseConfig | TokenConfig | SessionTokenConfig;
// const GraphqlProvider = ({ children, shop, token, sessionToken }: Params) => {
const GraphqlProvider: React.FC<{
  shop: string
  token?: string
  sessionToken?: string
}> = ({ children, shop, token, sessionToken }) => {
  if (typeof window !== "undefined" || !shop) {
    const httpLink = new HttpLink({
      uri: `/api/graphql`,
      includeExtensions: true,
    })

    const middlewareLink = setContext(() => {
      if (token) {
        const headers = {
          headers: {
            "X-Shopify-Access-Token": token,
            "X-Shopify-Shop-Domain": shop,
          },
        }
        return headers
      } else if (sessionToken) {
        return {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) {
        const networkErrorString = `${networkError}`

        if (has(networkError, "statusCode") && has(networkError, "response")) {
          const { statusCode } = networkError
          switch (statusCode) {
            case 403:
              console.error('HERE Disabled redirect to refresh auth')
              refreshAuth(shop, true)
              break
          }
        }

        // if (networkErrorString.includes("401")) {
        //   // delete cookies and retry auth as most likely stale token
        //   console.warn(`[Network error]: ${networkError}`)
        //   clearAppCookies(shop)
        //   navigate(`/reauth`, {
        //     replace: true,
        //   })
        // }
        // if (networkErrorString.includes("403")) {
        //   // delete cookies and retry auth as most likely stale token
        //   console.warn(`[Network error]: ${networkError}`)
        //   navigate(`/reauth`, {
        //     replace: true,
        //   })
        // }
      }
    })

    const client = new ApolloClient({
      link: ApolloLink.from([errorLink, middlewareLink, httpLink]),
      cache: new InMemoryCache(),
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
  } else {
    return { children }
  }
}

export default GraphqlProvider
