import React, { ReactNode, useEffect, useState } from "react"
import { Link as InternalLink, navigate } from "gatsby"
import { Provider } from "@shopify/app-bridge-react"
import { AppProvider } from "@shopify/polaris"
// import { Nullable } from "../helpers/types"

// import GraphqlProvider from "../providers/graphql"
// import { getFirebase } from "../helpers/firebase"
// import FirebaseContext from "../providers/firebase"

import {
  getShopToken,
  getShopDomain,
  isAuthenticated,
  refreshAuth,
} from "../helpers/auth"

import "@shopify/polaris/dist/styles.css"
import GraphqlProvider from "../providers/graphql"
import { apiKey } from "../helpers/app"
import { AppProps, ShopifyAppBrideState } from "../helpers/app-props"
import enTranslations from "@shopify/polaris/locales/en.json"

import "./app.css"
import { AppSplash } from "../templates/components/app-splash"
import { AppConfigProvider } from "../providers/app-config"
import { AppConfig, createApp } from "@shopify/app-bridge"
import { getSessionToken } from "@shopify/app-bridge-utils"
import { AuthProvider } from "../providers/auth"
// import { useFirebase } from "../helpers/firebase"

// import firebase from 'firebase/app'

// const initialState: AppProps = {
//   shop: undefined,
//   token: undefined,
//   isLoading: true,
// }
// type State = Readonly<typeof initialState>

// Don't load firebase here. Its not needed and depends on fetch via the browser
// so doesn't work in 8+
const AppLayout: React.FC<AppProps> = ({ children }): JSX.Element => {
  // let shop = null
  // let shop: State["shop"] = undefined
  const [bridgeConfig, setBridgeConfig] = useState<AppConfig>({} as AppConfig)
  const [shopOrigin, setShopOrigin] = useState<string>()
  const [gqlToken, setGqlToken] = useState<string>()
  const [sessionToken, setSessionToken] = useState<string>()
  const [appAuthenticated, setAppAuthenticated] = useState<boolean>()
  const [appName, setAppName] = useState<string>()
  const [bridgeApiKey, setBridgeApiKey] = useState(apiKey())

  /**
   * Redirect if not authenticated
   */
  useEffect(() => {
    if (typeof appAuthenticated !== "boolean") {
      // Unknown
    } else if (appAuthenticated === false && shopOrigin) {
      console.log("Refreshing auth for", { shopOrigin })
      refreshAuth(shopOrigin, true)
    }
  }, [appAuthenticated, shopOrigin])

  useEffect(() => {
    if (typeof window !== "undefined") {
      // console.warn("TOTALLY SKIPPING AUTH")
      // isAuthenticated().then(setAppAuthenticated)

      const shop = getShopDomain()
      // const urlParams = new URLSearchParams(window.location.search)
      // const token = urlParams.get("token") || undefined;
      // const token = getShopToken(shop)
      // console.log({token, shop});
      // const token = getShopToken(shopDomain)
      if (!shop || shop === null || typeof shop == "undefined") {
        console.log("Unable to read shop, redirecting to install")
        navigate(`/install`, {
          replace: true,
        })
      } else {
        setShopOrigin(shop)
        // setGqlToken(token);
      }
    }
  }, [])

  useEffect(() => {
    if (shopOrigin && bridgeApiKey) {
      const { parent, top, self } = window
      // If not in a frame move it back to Shopify. Wish app bridge said nope...
      if (top === self) {
        // console.log("Do some redirect")
        // const shopifyAppUrl = `https://${shopOrigin}/admin/apps/${bridgeApiKey}`
        // top.location.replace(shopifyAppUrl);
            refreshAuth(shopOrigin, true)
      } else {
        try {
          const app = createApp({
            apiKey: bridgeApiKey,
            shopOrigin,
          })
          const errorUnsubscribe = app.error((data) => {
            const { type, action, message } = data
            console.log({ type, action, message })
          })
          // console.log({ app })
          // Fallback for none-frame loads
          const reload = false
          if (reload) {
            refreshAuth(shopOrigin, true)
          }

          app
            .getState()
            .then((state: ShopifyAppBrideState) => {
              // console.log({ state })
              const { appInfo } = state.titleBar
              const { name } = appInfo
              setAppName(name)
            })
            .catch((error) => {
              console.error("app get state error %o", error)
            })
          // Test session tokens...
          getSessionToken(app)
            .then((sessionToken) => {
              // console.log({ sessionToken })
              if (sessionToken) {
                setSessionToken(sessionToken)
                setAppAuthenticated(true)
              }
            })
            .catch((error) => {
              console.error("Auth session token error %o", error)
            })
          setBridgeConfig({
            shopOrigin,
            apiKey: bridgeApiKey,
          })
          return errorUnsubscribe
        } catch (error) {
          console.error("Auth check error %o", error)
        }
      }
    }
  }, [shopOrigin, bridgeApiKey])

  if (!bridgeConfig.shopOrigin || !bridgeConfig.apiKey) {
    return (
      <p>
        Redirecting...
        {/* {shopOrigin} {bridgeApiKey} */}
      </p>
    )
    // return (<AppSplash />);
  }
  return (
    <Provider config={bridgeConfig}>
      <AppProvider
        // linkComponent={CustomLinkComponent}
        // forceRedirect={process.env.NODE_ENV === "development" ? false : true}
        i18n={enTranslations}
      >
        {appAuthenticated === true ? (
          <AppConfigProvider
            shop={bridgeConfig.shopOrigin}
            sessionToken={sessionToken}
          >
            <AuthProvider sessionToken={sessionToken}>
              <GraphqlProvider
                shop={bridgeConfig.shopOrigin}
                token={gqlToken}
                sessionToken={sessionToken}
              >
                {children}
              </GraphqlProvider>
            </AuthProvider>
          </AppConfigProvider>
        ) : (
          <AppSplash displayName={appName} />
        )}
      </AppProvider>
    </Provider>
  )
}

export default AppLayout
