import Cookies, { CookieSetOptions } from "universal-cookie";
import axios from "axios";
import { navigate } from "gatsby";
import { Nullable } from "./types";
import { isEqual } from "lodash";

const TEST_COOKIE_NAME = "shopify.cookies_persist"

const defaultCookieOptions = () => {
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000)
    const options: CookieSetOptions = {
        path: "/",
        expires: expirationDate,
        secure: true,
        sameSite: 'none',
    }
    return options;
}

// export const isAuthenticated = async (urlParamString?: string) => {
//     const cookies = new Cookies()

//     const queryParams = urlParamString || window.location.search || cookies.get("hmac_query")
//     const urlParams = new URLSearchParams(queryParams)
//     const shop = urlParams.get("shop")
//     const token = urlParams.get("token")
//     const expiresAt = urlParams.get("expires_at")
//     const uid = urlParams.get("uid")
//     // const params = Array.from(urlParams.entries).reduce((p,c) => ({...p, [c[0]]: c[1]}), {});

//     if (isAuthValid({ token, expiresAt, uid })) {
//         return true
//     }
//     const shopifyDomain = shop || getShopDomain()

//     if (!shopifyDomain) {
//         navigate("/install")
//         return false
//         //throw "No Shop domain"
//     }
//     //   console.log('Refresh auth');
//     return await refreshAuth(shopifyDomain)
// }

export const isAuthenticated = async (urlParamString?: string) => {
    const cookies = new Cookies();

    const queryParams = urlParamString || window.location.search || cookies.get("hmac_query")
    const urlParams = new URLSearchParams(queryParams)
    // console.log({urlParams, queryParams});
    const shop = urlParams.get("shop")
    const token = urlParams.get("token")
    const expiresAt = urlParams.get("expires_at")
    const uid = urlParams.get("uid")
    // const params = Array.from(urlParams.entries).reduce((p,c) => ({...p, [c[0]]: c[1]}), {});

    const isAuthValidResult = isAuthValid({ token, expiresAt, uid });
    // console.log({ isAuthValidResult, token, expiresAt, uid });
    if (isAuthValidResult) {
        return true
    }

    return false;
    // const shopifyDomain = shop || getShopDomain()

    // if (!shopifyDomain) {
    //     console.log('Not authenticated maybe redirect?');
    //     return false
    // }
    // return await refreshAuth(shopifyDomain)
}
export const getShopDomain = () => {
    const cookies = new Cookies()
    const queryParams = window.location.search || cookies.get(`hmac_query`)
    const urlParams = new URLSearchParams(queryParams)
    let parentDomain =
        document.location.ancestorOrigins && document.location.ancestorOrigins[0] // if chrome iframe
    const shopDomain = urlParams.get("shop")

    if (typeof parentDomain !== "undefined") {
        parentDomain = parentDomain.replace("https://", "")
    }

    return shopDomain || parentDomain
}

export const getShopToken = (domain: string) => {
    const shopDomain = domain || getShopDomain()
    const cookies = new Cookies()
    return cookies.get(`token_${shopDomain}`)
}

const isAuthValid = ({ token, expiresAt, uid }: {
    token?: Nullable<string>,
    expiresAt?: Nullable<string>,
    uid?: Nullable<string>
}) => {
    if (!token || !expiresAt || !uid) {
        return false;
    }
    const shopDomain = getShopDomain()
    const cookies = new Cookies()
    const cookie = {
        token: cookies.get(`token_${shopDomain}`),
        expiresAt: cookies.get(`expires_at_${shopDomain}`),
        uid: cookies.get(`uid_${shopDomain}`),
    }
    // TODO: 😟
    // Should you just trust the cookie?
    if (cookie.token && cookie.expiresAt && cookie.uid && cookie.token === token && cookie.uid === uid) {
        return true
    } else if (token && expiresAt && uid) {
        const options = defaultCookieOptions();
        cookies.set(`uid_${shopDomain}`, uid, options)
        cookies.set(`token_${shopDomain}`, token, options)
        cookies.set(`expires_at_${shopDomain}`, expiresAt, options);
        removeHmacQueryCookie()

        return { shopDomain }
    }

    return false
}

export const clearAppCookies = (shop: any) => {
    const cookies = new Cookies()

    cookies.remove(`token_${shop}`)
    cookies.remove(`expires_at_${shop}`)
    cookies.remove(`uid_${shop}`)

    return true
}

export const setHmacQueryCookie = (query: string) => {
    const cookies = new Cookies()
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + 5 * 60 * 1000) // 5 minutes

    cookies.set(`hmac_query`, query, { path: "/", expires: expirationDate, sameSite: 'none' })
}

export const removeHmacQueryCookie = () => {
    const cookies = new Cookies()
    cookies.remove(`hmac_query`)
}

export const refreshAuth = (shopDomain: string, force = false) => {
    const shop = shopDomain || getShopDomain()
    if (!shop) {
        console.log('no shop');
        return false
    }

    if (!hasCookieAccess(shop)) {
        console.log('unable to refresh, no cookie access')
        return false;
    }
    return axios
        .post("/auth", {
            shop,
        })
        .then(response => {
            if (response.data && response.data.body) {
                const redirectUrl = response.data.body
                const { status } = response.data
                // console.log('Auth status:', { status });
                // console.log(window);
                const { parent, top, self } = window;
                // If the all is not in a frame redirect? Not sure if this works as I expect
                let inFrame = false;
                try {
                    // If self and top are not the same. I guess if the app has a frame then loads it's self...
                    inFrame = !isEqual(self, top);
                } catch (e) {
                    // Same origin policies throw error when on a different domain so must be a frame
                    inFrame = true;
                }
                const forceRedirect = !inFrame || force;
                // console.log({forceRedirect, parent, top, self});
                if (status === 'update' || forceRedirect) {
                    // console.log('update location with new location', { redirectUrl });
                    if (top === self) {
                        window.top.location.href = redirectUrl
                    } else {
                        let normalizedLink = document.createElement("a")
                        normalizedLink.href = redirectUrl

                        const message = JSON.stringify({
                            message: "Shopify.API.remoteRedirect",
                            data: { location: normalizedLink.href },
                        })
                        window.parent.postMessage(message, `https://${shop}`)
                    }
                    // if (typeof parent.postMessage === 'function') {
                    //     console.log('b');
                    //     let normalizedLink = document.createElement("a")
                    //     normalizedLink.href = redirectUrl

                    //     const message = JSON.stringify({
                    //         message: "Shopify.API.remoteRedirect",
                    //         data: { location: normalizedLink.href },
                    //     })
                    //     window.parent.postMessage(message, `https://${shop}`)
                    // } else {
                    //     console.log('a');
                    //     window.top.location.href = redirectUrl
                    // }

                    return false;
                } else {
                    return true;
                }
            } else {
                console.error("Invalid /auth API response")
                return false
                // throw new Error("Invalid /auth API response")
            }
        })
        .catch(error => {
            console.warn('Unable to update auth', { error })
            return false
        })
}

const hasCookieAccess = (shop: any) => {
    if (shouldRequestStorage()) {
        // redirect to enable_cookie
        const redirectUrl = `${window.location.origin}/enable-cookies?shop=https://${shop}`

        if (window.top === window.self) {
            window.top.location.href = redirectUrl
        } else {
            let normalizedLink = document.createElement("a")
            normalizedLink.href = redirectUrl

            const message = JSON.stringify({
                message: "Shopify.API.remoteRedirect",
                data: { location: normalizedLink.href },
            })
            window.parent.postMessage(message, `https://${shop}`)
        }
        return false
    }

    return true
}

const shouldRequestStorage = () => {
    const cookies = new Cookies()

    if (cookies.get(TEST_COOKIE_NAME)) {
        return false
    }

    if (isShopifyiOS()) {
        return false
    }

    if (window.top === window.self) {
        return false
    }

    // if (userAgentCanPartitionCookies()) {
    //     return false
    // }

    if (!navigator.userAgent.match(/Safari/)) {
        return false
    }

    if (navigator.userAgent.match(/Chrome/)) {
        return false
    }

    return !cookies.get(TEST_COOKIE_NAME)
}

const isShopifyiOS = () => {
    if (navigator.userAgent.indexOf("com.jadedpixel.pos") !== -1) {
        return true
    }

    if (navigator.userAgent.indexOf("Shopify Mobile/iOS") !== -1) {
        return true
    }

    return false
}

// const userAgentCanPartitionCookies = () => {
//   if (isShopifyiOS()) {
//     return false
//   }

//   return navigator.userAgent.match(/Version\/12\.0\.?\d? Safari/)
// }

export const getAuthUID = (shopDomain: any) => {
    const cookies = new Cookies()
    return cookies.get(`uid_${shopDomain}`)
}
